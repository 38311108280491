import type { QueryOrder } from "@cubejs-client/core";

export const COST_DASHBOARD_ACTION_BAR_TABS = {
  default: {
    opportunities: "Opportunities",
    accounts: "Accounts",
    tag: "Tag:",
    resources: "Resources",
  },
  opportunitySelected: {
    accounts: "Accounts",
    tag: "Tag:",
    resources: "Resources",
  },
} as const;

export const COST_DASHBOARD_ACTION_BAR_KEY_MAP = {
  sortBy: "sort-by",
  tab: "tab",
  tag: "tag",
} as const;

export const COST_DASHBOARD_ACTION_BAR_SORT_OPTIONS = [
  "Highest Savings Opportunity",
  "Lowest Savings Opportunity",
  "Most Resource Matches",
  "Fewest Resource Matches",
];

export const COST_DASHBOARD_ACTION_BAR_TAG_OPTIONS = [
  "Name",
  "CreatorEmail",
  "CreatorName",
  "Owner",
  "Network",
  "Env",
  "Application",
  "Contact",
  "CostCentre",
  "Stage",
];

export interface CostDashboardActionBarState {
  sortBy: string;
  tab: string;
  tag: string;
}

const DEFAULT_ACTION_BAR_STATE: CostDashboardActionBarState = {
  sortBy: COST_DASHBOARD_ACTION_BAR_SORT_OPTIONS[0],
  tab: COST_DASHBOARD_ACTION_BAR_TABS.default.opportunities,
  tag: "",
};

/**
 * Get initial state of the action bar from URL params
 *
 * @param searchParams - search params from useSearchParams
 * @returns initial state of the action bar
 */
export const getInitialActionBarState = (
  searchParams: URLSearchParams,
  hasSelectedOpportunity: boolean,
): CostDashboardActionBarState => {
  const sortBy =
    searchParams.get(COST_DASHBOARD_ACTION_BAR_KEY_MAP.sortBy) ||
    DEFAULT_ACTION_BAR_STATE.sortBy;
  const tab =
    searchParams.get(COST_DASHBOARD_ACTION_BAR_KEY_MAP.tab) ||
    (hasSelectedOpportunity
      ? COST_DASHBOARD_ACTION_BAR_TABS.opportunitySelected.accounts
      : DEFAULT_ACTION_BAR_STATE.tab);
  const tag = searchParams.get(COST_DASHBOARD_ACTION_BAR_KEY_MAP.tag) || "";
  return {
    sortBy,
    tab,
    tag,
  };
};

/**
 * Updates the action bar state in URL params
 *
 * @param actionBarState - current state of action bar
 * @param searchParams - search params from useSearchParams
 * @param setSearchParams - setter function from useSearchParams
 */
export const updateActionBarState = (
  actionBarState: CostDashboardActionBarState,
  searchParams: URLSearchParams,
  setSearchParams: (
    nextInit: URLSearchParams,
    navigateOptions?: {
      replace?: boolean | undefined;
      state?: unknown;
    },
  ) => void,
) => {
  searchParams.set(
    COST_DASHBOARD_ACTION_BAR_KEY_MAP.sortBy,
    actionBarState.sortBy,
  );
  searchParams.set(COST_DASHBOARD_ACTION_BAR_KEY_MAP.tab, actionBarState.tab);
  searchParams.set(COST_DASHBOARD_ACTION_BAR_KEY_MAP.tag, actionBarState.tag);
  setSearchParams(searchParams);
};

export type ActionBarSortBy = [string, QueryOrder];

/**
 * Build sort by clause for cube
 *
 * @param sortBy - sort by option
 * @param cube - cube name
 * @returns sort by clause
 */
export function buildSortBy(sortBy: string, cube: string): ActionBarSortBy {
  switch (sortBy) {
    case "Highest Savings Opportunity":
      return [`${cube}.monthlySavingOpportunity`, "desc"];
    case "Lowest Savings Opportunity":
      return [`${cube}.monthlySavingOpportunity`, "asc"];
    case "Most Resource Matches":
      return [`${cube}.nonCompliantResourceCount`, "desc"];
    case "Fewest Resource Matches":
      return [`${cube}.nonCompliantResourceCount`, "asc"];
    default:
      return [`${cube}.monthlySavingOpportunity`, "desc"];
  }
}
