import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@stacklet/ui";
import { useNavigate } from "react-router";

import { StatusIcon } from "../icons/StatusIcon";

interface Props {
  resetErrorBoundary(): void;
  goBack?: boolean;
  tryAgain?: boolean;
  message?: string | undefined;
  height?: number | string;
  heading?: boolean;
  border?: boolean;
}

const borderStyles = {
  m: 1,
  p: 3,
  border: "solid 1px #D3D5D7",
  borderRadius: 1,
};

export function ErrorFallback({
  resetErrorBoundary,
  goBack = true,
  tryAgain = false,
  message,
  height,
  heading = true,
  border,
}: Props) {
  const navigate = useNavigate();

  const errorMessage =
    message || "Something went wrong. Please try again later.";

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      minHeight={height ? height : "100vh"}
      sx={border ? { ...borderStyles } : undefined}
    >
      {heading ? (
        <Typography
          component="div"
          sx={{
            mb: 1,
            fontSize: (theme) => theme.typography.pxToRem(72),
            fontWeight: 700,
          }}
        >
          Error
        </Typography>
      ) : null}
      <Typography component="div" sx={{ mb: 4 }} variant="body2">
        <Box sx={{ display: "inline-flex", alignItems: "baseline" }}>
          <StatusIcon status="error" inline />
          <span>{errorMessage}</span>
        </Box>
      </Typography>
      {tryAgain ? (
        <Button buttonType="main" onClick={resetErrorBoundary}>
          Try again?
        </Button>
      ) : null}
      {goBack && !tryAgain ? (
        <Button buttonType="main" onClick={() => navigate(-1)}>
          Go back
        </Button>
      ) : null}
    </Box>
  );
}
