/**
 * @generated SignedSource<<6a59507dbfee49ab97496cb2ed848920>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AWSAccessRoleInput = {
  accountDiscovery?: string | null | undefined;
  primary?: string | null | undefined;
  readOnly?: string | null | undefined;
  remediationPolicy?: string | null | undefined;
};
export type SetAwsAccessRolesMutation$variables = {
  input: AWSAccessRoleInput;
};
export type SetAwsAccessRolesMutation$data = {
  readonly setAWSAccessRoles: {
    readonly accountDiscovery: {
      readonly arn: string | null | undefined;
      readonly error: string | null | undefined;
    } | null | undefined;
    readonly primary: {
      readonly arn: string | null | undefined;
      readonly error: string | null | undefined;
    } | null | undefined;
    readonly readOnly: {
      readonly arn: string | null | undefined;
      readonly error: string | null | undefined;
    } | null | undefined;
    readonly remediationPolicy: {
      readonly arn: string | null | undefined;
      readonly error: string | null | undefined;
    } | null | undefined;
  };
};
export type SetAwsAccessRolesMutation = {
  response: SetAwsAccessRolesMutation$data;
  variables: SetAwsAccessRolesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "arn",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "error",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SetAWSAccessRolePayload",
    "kind": "LinkedField",
    "name": "setAWSAccessRoles",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AWSRolePayload",
        "kind": "LinkedField",
        "name": "primary",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AWSRolePayload",
        "kind": "LinkedField",
        "name": "accountDiscovery",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AWSRolePayload",
        "kind": "LinkedField",
        "name": "readOnly",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AWSRolePayload",
        "kind": "LinkedField",
        "name": "remediationPolicy",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SetAwsAccessRolesMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SetAwsAccessRolesMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "a5d9526b62e5ac4dbaa5f0d7df24d8c0",
    "id": null,
    "metadata": {},
    "name": "SetAwsAccessRolesMutation",
    "operationKind": "mutation",
    "text": "mutation SetAwsAccessRolesMutation(\n  $input: AWSAccessRoleInput!\n) {\n  setAWSAccessRoles(input: $input) {\n    primary {\n      arn\n      error\n    }\n    accountDiscovery {\n      arn\n      error\n    }\n    readOnly {\n      arn\n      error\n    }\n    remediationPolicy {\n      arn\n      error\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "67a22250ccf31d7a1bbbde91505982a2";

export default node;
