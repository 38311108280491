import { Suspense } from "react";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { theme } from "@stacklet/ui";
import { graphql, useLazyLoadQuery } from "react-relay";
import { useNavigate, useSearchParams } from "react-router";

import { useTabsRef } from "app/hooks";

import { AccessModelTab } from "./AccessModel/AccessModelTab";
import { AdminSettingsPage } from "./AdminSettingsPage";

import type { AdminSettingsRootQuery } from "./__generated__/AdminSettingsRootQuery.graphql";

export function AdminSettingsRoot() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams({});
  const view = searchParams.get("view");
  const tabValue = view || "access-model";
  const tabsRef = useTabsRef();

  const pxToRem = theme.typography.pxToRem;

  const data = useLazyLoadQuery<AdminSettingsRootQuery>(
    graphql`
      query AdminSettingsRootQuery {
        ...AdminSettingsPage_query
        ...AccessModelTab_awsAccessRoles
      }
    `,
    {},
  );

  return (
    <AdminSettingsPage queryRef={data}>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Tabs
          TabIndicatorProps={{
            style: {
              width: pxToRem(2),
              right: pxToRem(-1),
              height: pxToRem(36),
            },
          }}
          action={tabsRef}
          aria-label="Admin tabs"
          orientation="vertical"
          sx={{
            height: "100%",
            width: pxToRem(125),
            overflow: "visible",
            "& .MuiTabs-scroller": {
              overflow: "visible!important",
            },
          }}
          value={tabValue}
        >
          <Tab
            label="Access Model"
            onClick={() => navigate("?view=access-model")}
            sx={{ height: pxToRem(36), minHeight: pxToRem(36) }}
            value="access-model"
          />
        </Tabs>
        <Suspense fallback={null}>
          {!view || view === "access-model" ? (
            <AccessModelTab queryRef={data} />
          ) : null}
        </Suspense>
      </Box>
    </AdminSettingsPage>
  );
}
