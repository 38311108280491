import type { ElementType } from "react";

import Box from "@mui/material/Box";
import {
  Tooltip,
  AwsLogo,
  AwsWhiteLogo,
  AzureLogo,
  GcpLogo,
  TencentCloudLogo,
} from "@stacklet/ui";
import { nanoid } from "nanoid";

import type { CloudProvider } from "app/mutations/__generated__/AddAccountGroupMutation.graphql";

export function getProviderIcon(
  provider: CloudProvider,
  invert?: boolean,
): ElementType {
  switch (provider) {
    case "AWS":
      if (invert) {
        return AwsWhiteLogo;
      } else {
        return AwsLogo;
      }

    case "Azure":
      return AzureLogo;

    case "GCP":
      return GcpLogo;

    case "TencentCloud":
      return TencentCloudLogo;

    case "Kubernetes":
      // Use AwsLogo as a fallback for Kubernetes until it's added to @stacklet/ui
      return AwsLogo;

    default:
      return AwsLogo;
  }
}
interface Props {
  provider: CloudProvider;
  invert?: boolean;
}

export function ProviderIcon({ provider = "AWS", invert = false }: Props) {
  const ProviderLogoComponent = getProviderIcon(provider, invert);
  const id = `provider-tooltip-${nanoid()}`;

  return (
    <Box
      sx={{
        display: "flex",
        width: "24px",
      }}
    >
      <Tooltip data-testid="provider-tooltip" id={id} title={provider}>
        <span>
          <ProviderLogoComponent aria-labelledby={id} width={22} />
        </span>
      </Tooltip>
    </Box>
  );
}
