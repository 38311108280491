import { type MouseEvent, useState } from "react";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as Sentry from "@sentry/browser";
import { Button, COLORS, theme, WithCopyButton } from "@stacklet/ui";
import { Controller, useForm } from "react-hook-form";
import { graphql, useFragment, useMutation } from "react-relay";
import { z } from "zod";

import { useAlertContext } from "app/contexts";
import { SetAwsAccessRolesMutation } from "app/mutations/SetAwsAccessRoles";
import { trimString } from "app/utils/trimString";
import validateArn from "app/utils/validateArn";
import EditIcon from "assets/icons/edit.svg?react";

import type { GatewayRolesForm_awsAccessRoles$key } from "./__generated__/GatewayRolesForm_awsAccessRoles.graphql";
import type {
  SetAwsAccessRolesMutation$data,
  SetAwsAccessRolesMutation as SetAwsAccessRolesMutationType,
} from "app/mutations/__generated__/SetAwsAccessRolesMutation.graphql";
import type { RecordSourceSelectorProxy } from "relay-runtime";
import type { ZodSchema } from "zod";

const pxToRem = theme.typography.pxToRem;

interface ValidRoleConnectionRowProps {
  isValidConnection?: string | null; // undefined signifies not yet validated
  isValidRoleArn?: boolean; // undefined signifies not yet validated
}

function ValidRoleConnectionRow({
  isValidRoleArn,
  isValidConnection,
}: ValidRoleConnectionRowProps) {
  return (
    <Stack alignItems="center" direction="row" spacing={8}>
      <Box alignItems="center" display="flex" gap={2}>
        <Box
          sx={{
            width: pxToRem(12),
            height: pxToRem(12),
            backgroundColor:
              isValidRoleArn === undefined
                ? COLORS.neutrals.denseFog
                : isValidRoleArn === true
                  ? COLORS.green.D30
                  : COLORS.red.D20,
            borderRadius: "50%",
          }}
        ></Box>
        <Typography fontSize={pxToRem(12)}>Valid Role ARN</Typography>
      </Box>
      <Box alignItems="center" display="flex" gap={2}>
        <Box
          sx={{
            width: pxToRem(12),
            height: pxToRem(12),
            backgroundColor:
              isValidConnection === undefined
                ? COLORS.neutrals.denseFog
                : isValidConnection === null
                  ? COLORS.green.D30
                  : COLORS.red.D20,
            borderRadius: "50%",
          }}
        ></Box>
        <Typography fontSize={pxToRem(12)}>Connection Test</Typography>
      </Box>
    </Stack>
  );
}

const TextFieldInputProps = {
  sx: {
    fontSize: pxToRem(12),
  },
};

function handleErrors(awsAccessRoles: SetAwsAccessRolesMutation$data) {
  // we need errors in ordered format so we can not pass subsequent roles if the previous one has an error
  const errorsList = [
    "primary",
    "readOnly",
    "accountDiscovery",
    "remediationPolicy",
  ];
  const errorsResponse: Record<string, string | null | undefined> = {
    primary: undefined,
    readOnly: undefined,
    accountDiscovery: undefined,
    remediationPolicy: undefined,
  };
  let firstError = false;
  errorsList.forEach((errorKey) => {
    const error =
      awsAccessRoles.setAWSAccessRoles[
        errorKey as keyof typeof awsAccessRoles.setAWSAccessRoles
      ]?.error || (firstError ? undefined : null);
    if (error) firstError = true;
    errorsResponse[errorKey] = error;
  });

  return errorsResponse;
}

export function setAwsAccessRolesUpdater(
  store: RecordSourceSelectorProxy<SetAwsAccessRolesMutation$data>,
  response: SetAwsAccessRolesMutation$data | null | undefined,
) {
  if (!response || !response.setAWSAccessRoles) return;

  const root = store.getRoot();

  const awsAccessRolesRecord = root.getLinkedRecord("awsAccessRoles");
  if (!awsAccessRolesRecord) return;

  Object.keys(response.setAWSAccessRoles).forEach((roleName) => {
    const roles = response.setAWSAccessRoles;
    const arn = roles[roleName as keyof typeof roles]?.arn;
    awsAccessRolesRecord.setValue(arn, `${roleName}RoleARN`);
  });
}

const roleArnPreProcess = z.preprocess(
  trimString,
  z
    .string()
    .optional()
    // validation for ARN format
    .refine((val) => (val ? validateArn(val) : true), {
      message:
        "Invalid ARN format, should be in the format: arn:aws:iam:{account_id}:role/{role_name}",
    }),
);

const optionalRoleArnPreProcess = z.optional(roleArnPreProcess);

const schema = z
  .object({
    primaryRoleARN: optionalRoleArnPreProcess,
    readOnlyRoleARN: optionalRoleArnPreProcess,
    accountDiscoveryRoleARN: optionalRoleArnPreProcess,
    remediationPolicyRoleARN: optionalRoleArnPreProcess,
  })
  .refine(
    (data) => {
      // Validation logic: primaryRoleARN or all other roles must be provided
      return (
        data.primaryRoleARN ||
        (data.readOnlyRoleARN &&
          data.accountDiscoveryRoleARN &&
          data.remediationPolicyRoleARN)
      );
    },
    {
      message:
        "Either the primary role ARN or all three secondary roles must be provided",
      path: ["root"],
    },
  );

type FormData = {
  root?: string;
} & z.infer<typeof schema>;

interface Props {
  queryRef: GatewayRolesForm_awsAccessRoles$key;
  viewOnly?: boolean;
}

export function GatewayRolesForm({ queryRef, viewOnly = true }: Props) {
  const { alertDispatch } = useAlertContext();
  const [graphqlError, setGraphqlError] = useState<Error | null>(null);
  const [errors, setErrors] = useState<
    Record<string, string | undefined | null>
  >({});
  const [isViewOnly, setIsViewOnly] = useState(viewOnly);
  const [changesMsg, setChangesMsg] = useState("");

  const { control, handleSubmit, formState, setError, clearErrors } =
    useForm<FormData>({
      mode: "onChange",
      resolver: customZodResolver(schema),
    });

  function customZodResolver(schema: ZodSchema) {
    // Custom resolver to handle the schema level validation
    return async (data: FormData) => {
      const validationResult = schema.safeParse(data);
      const errors: Record<string, { type: string; message: string }> = {};
      if (!validationResult.success) {
        validationResult.error.errors.forEach((error) => {
          const path = error.path.join(".");
          // Add schema-level error
          if (path === "root") {
            setError("root", { type: "manual", message: error.message });
          } else {
            // Add field-level errors
            errors[path] = {
              type: "manual",
              message: error.message,
            };
          }
        });
      } else clearErrors();

      return {
        values: validationResult.success ? validationResult.data : {},
        errors,
      };
    };
  }

  const isValid = formState.isValid;

  function handleFieldChange() {
    if (isViewOnly) return;
    setChangesMsg("Changes not saved");
  }

  const data = useFragment<GatewayRolesForm_awsAccessRoles$key>(
    graphql`
      fragment GatewayRolesForm_awsAccessRoles on Query {
        awsAccessRoles {
          accountDiscoveryRoleARN
          primaryRoleARN
          readOnlyRoleARN
          remediationPolicyRoleARN
          samplePrimaryTrust
        }
      }
    `,
    queryRef,
  );

  const {
    primaryRoleARN,
    readOnlyRoleARN,
    accountDiscoveryRoleARN,
    remediationPolicyRoleARN,
    samplePrimaryTrust,
  } = data.awsAccessRoles;

  const [updateAwsAccessRoles, isInFlight] =
    useMutation<SetAwsAccessRolesMutationType>(SetAwsAccessRolesMutation);

  const handleUpdateAwsAccessRoles = (formData: FormData) => {
    const input = {
      primary: formData.primaryRoleARN,
      readOnly: formData.readOnlyRoleARN,
      accountDiscovery: formData.accountDiscoveryRoleARN,
      remediationPolicy: formData.remediationPolicyRoleARN,
    };

    updateAwsAccessRoles({
      variables: { input },
      onCompleted: (response) => {
        const errorsResponse = handleErrors(response);
        setErrors(errorsResponse);
        setGraphqlError(null);
        const hasError = Object.values(errorsResponse).some(
          (error) => error !== null && error !== undefined,
        );
        if (!hasError) {
          alertDispatch({
            message: "Gateway roles updates successfully",
            severity: "success",
          });
          setChangesMsg("Changes saved");
          setIsViewOnly(true);
          setTimeout(() => {
            setChangesMsg("");
          }, 2000);
        }
      },
      onError: (error) => {
        Sentry.captureException(error);
        setGraphqlError(error);
      },
      updater: setAwsAccessRolesUpdater,
    });
  };
  return (
    <form
      aria-label="upsert-email-form"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit((data) => {
          handleUpdateAwsAccessRoles(data);
        })(e);
      }}
    >
      <Stack spacing={pxToRem(20)}>
        <FormControl margin="normal" variant="standard" fullWidth>
          <Controller
            control={control}
            defaultValue={primaryRoleARN || ""}
            name="primaryRoleARN"
            render={({ field, fieldState }) => (
              <>
                <Box display="flex" flexDirection="column">
                  <Typography fontSize={pxToRem(14)} paddingBottom={pxToRem(8)}>
                    Primary Role
                  </Typography>
                  <TextField
                    {...field}
                    InputProps={{
                      sx: {
                        ...TextFieldInputProps.sx,
                        backgroundColor: isViewOnly ? COLORS.navy.L95 : "",
                      },
                    }}
                    data-testid="primaryRoleARN"
                    disabled={isViewOnly}
                    error={fieldState.invalid || Boolean(errors.primary)}
                    helperText={fieldState?.error?.message || errors.primary}
                    id="primaryRoleARN"
                    onChange={(e) => {
                      field.onChange(e);
                      handleFieldChange();
                    }}
                    placeholder="An AWS Role ARN"
                    size="small"
                    sx={{ paddingBottom: pxToRem(10) }}
                    variant="outlined"
                  />
                </Box>
                <ValidRoleConnectionRow
                  isValidConnection={errors.primary}
                  isValidRoleArn={field.value ? !fieldState.invalid : undefined}
                />
              </>
            )}
          />
        </FormControl>
        <Box display="flex" flexDirection="column" gap={pxToRem(14)}>
          <Typography fontSize={pxToRem(14)}>
            Additional Gateway Roles
          </Typography>
          <Typography color={COLORS.navy.L30} fontSize={pxToRem(12)}>
            You can also add additional roles to the assume role chain. If the
            primary role is enabled, Stacklet will first assume into the primary
            role and then assume role again to the next role based on the
            context. Optionally, you can supply just the Gateway Roles for each
            context.
          </Typography>
          <WithCopyButton
            copyLabel="Trust Relationship"
            copyWithIcon={false}
            sx={{
              fontSize: pxToRem(12),
            }}
            value={samplePrimaryTrust || ""}
          >
            Copy Trust Relationship
          </WithCopyButton>
        </Box>
        <FormControl margin="normal" variant="standard" fullWidth>
          <Controller
            control={control}
            defaultValue={readOnlyRoleARN || ""}
            name="readOnlyRoleARN"
            render={({ field, fieldState }) => (
              <>
                <Box display="flex" flexDirection="column">
                  <Typography fontSize={pxToRem(14)} paddingBottom={pxToRem(8)}>
                    Read-Only Role
                  </Typography>
                  <TextField
                    {...field}
                    InputProps={{
                      sx: {
                        ...TextFieldInputProps.sx,
                        backgroundColor: isViewOnly ? COLORS.navy.L95 : "",
                      },
                    }}
                    data-testid="readOnlyRoleARN"
                    disabled={isViewOnly}
                    error={fieldState.invalid || Boolean(errors.readOnly)}
                    helperText={fieldState?.error?.message || errors.readOnly}
                    id="readOnlyRoleARN"
                    onChange={(e) => {
                      field.onChange(e);
                      handleFieldChange();
                    }}
                    placeholder="An AWS Role ARN"
                    size="small"
                    sx={{ paddingBottom: pxToRem(10) }}
                    variant="outlined"
                  />
                </Box>
                <ValidRoleConnectionRow
                  isValidConnection={errors.readOnly}
                  isValidRoleArn={field.value ? !fieldState.invalid : undefined}
                />
              </>
            )}
          />
        </FormControl>
        <FormControl margin="normal" variant="standard" fullWidth>
          <Controller
            control={control}
            defaultValue={accountDiscoveryRoleARN || ""}
            name="accountDiscoveryRoleARN"
            render={({ field, fieldState }) => (
              <>
                <Box display="flex" flexDirection="column">
                  <Typography fontSize={pxToRem(14)} paddingBottom={pxToRem(8)}>
                    Account Discover Role
                  </Typography>
                  <TextField
                    {...field}
                    InputProps={{
                      sx: {
                        ...TextFieldInputProps.sx,
                        backgroundColor: isViewOnly ? COLORS.navy.L95 : "",
                      },
                    }}
                    data-testid="accountDiscoveryRoleARN"
                    disabled={isViewOnly}
                    error={
                      fieldState.invalid || Boolean(errors.accountDiscovery)
                    }
                    helperText={
                      fieldState?.error?.message || errors.accountDiscovery
                    }
                    id="accountDiscoveryRoleARN"
                    onChange={(e) => {
                      field.onChange(e);
                      handleFieldChange();
                    }}
                    placeholder="An AWS Role ARN"
                    size="small"
                    sx={{ paddingBottom: pxToRem(10) }}
                    variant="outlined"
                  />
                </Box>
                <ValidRoleConnectionRow
                  isValidConnection={errors.accountDiscovery}
                  isValidRoleArn={field.value ? !fieldState.invalid : undefined}
                />
              </>
            )}
          />
        </FormControl>
        <FormControl margin="normal" variant="standard" fullWidth>
          <Controller
            control={control}
            defaultValue={remediationPolicyRoleARN || ""}
            name="remediationPolicyRoleARN"
            render={({ field, fieldState }) => (
              <>
                <Box display="flex" flexDirection="column">
                  <Typography fontSize={pxToRem(14)} paddingBottom={pxToRem(8)}>
                    Policy Remediation Role
                  </Typography>
                  <TextField
                    {...field}
                    InputProps={{
                      sx: {
                        ...TextFieldInputProps.sx,
                        backgroundColor: isViewOnly ? COLORS.navy.L95 : "",
                      },
                    }}
                    data-testid="remediationPolicyRoleARN"
                    disabled={isViewOnly}
                    error={
                      fieldState.invalid || Boolean(errors.remediationPolicy)
                    }
                    helperText={
                      fieldState?.error?.message || errors.remediationPolicy
                    }
                    id="remediationPolicyRoleARN"
                    onChange={(e) => {
                      field.onChange(e);
                      handleFieldChange();
                    }}
                    placeholder="An AWS Role ARN"
                    size="small"
                    sx={{ paddingBottom: pxToRem(10) }}
                    variant="outlined"
                  />
                </Box>
                <ValidRoleConnectionRow
                  isValidConnection={errors.remediationPolicy}
                  isValidRoleArn={field.value ? !fieldState.invalid : undefined}
                />
              </>
            )}
          />
        </FormControl>

        <Box alignItems="center" display="flex" gap={pxToRem(10)}>
          {isViewOnly ? (
            <Button
              buttonType="outline-main"
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                setIsViewOnly(false);
              }}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
              type="button"
            >
              <EditIcon
                fill={COLORS.orange.D10}
                height={pxToRem(20)}
                width={pxToRem(20)}
              />
              Edit Configuration
            </Button>
          ) : (
            <Button
              buttonType="main"
              data-testid="access-model-button"
              isDisabled={
                !isValid ||
                isInFlight ||
                !formState.isDirty ||
                Boolean(formState.errors.root)
              }
              loading={{
                isLoading: isInFlight,
                loadingMessage: "saving...",
              }}
              type="submit"
            >
              Save
            </Button>
          )}
          <FormHelperText>{changesMsg}</FormHelperText>
        </Box>
        {formState.errors.root &&
        Object.values(formState.touchedFields).some((field) => !!field) ? (
          <FormHelperText sx={{ color: "#d32f2f" }}>
            {formState.errors.root.message}
          </FormHelperText>
        ) : null}
        {graphqlError ? (
          <Alert severity="error">{graphqlError.message}</Alert>
        ) : null}
      </Stack>
    </form>
  );
}
