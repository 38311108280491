import { Auth } from "aws-amplify";
import Cookies from "universal-cookie";

export interface CookieOptions {
  domain: string;
  path: string;
  secure: boolean;
}

export function getAuthCookieOptions(): CookieOptions {
  return {
    domain:
      window.location.hostname.split(".").length > 2
        ? window.location.hostname.replace(/^[^.]*/, "")
        : window.location.hostname,
    path: "/",
    secure: window.location.protocol == "https:",
  };
}

async function getSession() {
  return await Auth.currentSession().catch(() => {
    Auth.signOut();
  });
}

export async function getAuthToken(): Promise<string> {
  const session = await getSession();

  if (session) {
    return session.getAccessToken().getJwtToken();
  }

  return "Unauthenticated";
}

export async function getIdToken(): Promise<string> {
  const session = await getSession();

  if (session) {
    return session.getIdToken().getJwtToken();
  }

  return "Unauthenticated";
}

export async function authCookieHandler() {
  const cookies = new Cookies();
  for (const key in cookies.getAll()) {
    if (
      key.startsWith("CognitoIdentityServiceProvider.") &&
      key.endsWith(".userData") &&
      Object.keys(cookies.get(key)).length == 0
    ) {
      // Work around https://github.com/aws-amplify/amplify-js/issues/11106
      // For some reason, if the userData cookie is empty, the Auth.currentSession
      // below explodes and the .catch() on it doesn't help. Removing the cookie
      // just forces Amplify to rebuild it.
      cookies.remove(key, getAuthCookieOptions());
    }
  }
  // can't use getSession because that will cause a signOut event infinite loop
  const session = await Auth.currentSession().catch(() => null);

  if (session) {
    cookies.set("stacklet-auth", await getIdToken(), getAuthCookieOptions());
  } else {
    cookies.remove("stacklet-auth", getAuthCookieOptions());
  }
}
