/**
 * @generated SignedSource<<5949951c74f732155376c78b0f22006a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccessModelTab_awsAccessRoles$data = {
  readonly awsAccessRoles: {
    readonly externalID: string | null | undefined;
    readonly primaryRoleARN: string | null | undefined;
    readonly samplePrimaryTrust: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"GatewayRolesForm_awsAccessRoles">;
  readonly " $fragmentType": "AccessModelTab_awsAccessRoles";
};
export type AccessModelTab_awsAccessRoles$key = {
  readonly " $data"?: AccessModelTab_awsAccessRoles$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccessModelTab_awsAccessRoles">;
};

import AccessModelTabRefetchQuery_graphql from './AccessModelTabRefetchQuery.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": AccessModelTabRefetchQuery_graphql
    }
  },
  "name": "AccessModelTab_awsAccessRoles",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GatewayRolesForm_awsAccessRoles"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AWSAccessRoles",
      "kind": "LinkedField",
      "name": "awsAccessRoles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "externalID",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "primaryRoleARN",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "samplePrimaryTrust",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "71432517c18fbfb3722d19cdf8fd7c5a";

export default node;
