import { useMemo } from "react";

import { type Provider, useProvider } from "app/contexts/ProviderContext";
import { formatProvider } from "app/utils/formatProvider";

import type { FilterBooleanOperator } from "__generated__/AppQuery.graphql";

export function getEnabledDashboardsFilterElement(provider: Provider) {
  const filterElement = useMemo(() => {
    const providerFilter = {
      single: {
        name: "provider",
        value: formatProvider(provider),
      },
    };
    const systemFilter = {
      single: { name: "system", value: "true" },
    };

    return {
      multiple: {
        operands: [providerFilter, systemFilter],
        operator: "AND" as FilterBooleanOperator,
      },
    };
  }, [provider]);

  return filterElement;
}

export function useEnabledDashboardsFilterElement() {
  const provider = useProvider();

  return getEnabledDashboardsFilterElement(provider);
}
