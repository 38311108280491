window.global ||= window; // https://github.com/vitejs/vite/issues/2778#issuecomment-810086159

import React, { Suspense } from "react";

import { Authenticator } from "@aws-amplify/ui-react";
import GlobalStyles from "@mui/material/GlobalStyles";
import { StyledEngineProvider } from "@mui/material/styles";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { StackletUIThemeProvider, theme } from "@stacklet/ui";
import { ConfirmProvider } from "material-ui-confirm";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { RelayEnvironmentProvider } from "react-relay/hooks";
import { BrowserRouter } from "react-router";

import Login from "app/components/authentication/Login";
import { CircularFallback } from "app/components/fallbacks";
import { AnalyticsProvider, ConfigProvider } from "app/contexts";

import App from "./App";
import { bootstrapStacklet } from "./bootstrap";
import RelayEnvironment from "./RelayEnvironment";
import reportWebVitals from "./reportWebVitals";

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_X_LICENSE ?? "");

// Supports weights 100-900
import "@fontsource-variable/dm-sans";

// Weight 400 only
import "@fontsource/dm-mono";

const container = document.getElementById("root");
// eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

const globalStyles = (
  <GlobalStyles
    styles={{
      "*": {
        fontVariantLigatures: "none",
        fontFeatureSettings: "liga 0",
      },
    }}
  />
);

function run() {
  bootstrapStacklet().then(() => {
    root.render(
      <React.StrictMode>
        <ConfigProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <StackletUIThemeProvider>
                {globalStyles}
                <Authenticator.Provider>
                  <Login>
                    <RelayEnvironmentProvider environment={RelayEnvironment}>
                      <BrowserRouter>
                        <Suspense fallback={<CircularFallback />}>
                          <AnalyticsProvider>
                            <HelmetProvider>
                              <ConfirmProvider>
                                <App />
                              </ConfirmProvider>
                            </HelmetProvider>
                          </AnalyticsProvider>
                        </Suspense>
                      </BrowserRouter>
                    </RelayEnvironmentProvider>
                  </Login>
                </Authenticator.Provider>
              </StackletUIThemeProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </ConfigProvider>
      </React.StrictMode>,
    );
  });
}

run();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
