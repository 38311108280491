/**
 * @generated SignedSource<<68adb5054759d3e643754bfc852f0120>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type RepositoryInput = {
  authToken?: string | null | undefined;
  authUser?: string | null | undefined;
  branchName?: string | null | undefined;
  deepImport?: boolean | null | undefined;
  description?: string | null | undefined;
  name: string;
  policyDirectories?: ReadonlyArray<string> | null | undefined;
  policyFileSuffix?: ReadonlyArray<string> | null | undefined;
  sshPassphrase?: string | null | undefined;
  sshPrivateKey?: string | null | undefined;
  url: string;
};
export type AddRepositoryMutation$variables = {
  connections: ReadonlyArray<string>;
  input: RepositoryInput;
};
export type AddRepositoryMutation$data = {
  readonly addRepository: {
    readonly repository: {
      readonly authUser: string | null | undefined;
      readonly branchName: string | null | undefined;
      readonly description: string | null | undefined;
      readonly error: string | null | undefined;
      readonly hasAuthToken: boolean | null | undefined;
      readonly hasSshPassphrase: boolean | null | undefined;
      readonly hasSshPrivateKey: boolean | null | undefined;
      readonly head: string | null | undefined;
      readonly id: string;
      readonly lastScanned: string | null | undefined;
      readonly name: string;
      readonly policyDirectories: ReadonlyArray<string> | null | undefined;
      readonly policyFileSuffix: ReadonlyArray<string> | null | undefined;
      readonly url: string;
      readonly uuid: string;
    } | null | undefined;
  } | null | undefined;
};
export type AddRepositoryMutation = {
  response: AddRepositoryMutation$data;
  variables: AddRepositoryMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Repository",
  "kind": "LinkedField",
  "name": "repository",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "head",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "authUser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasAuthToken",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasSshPassphrase",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasSshPrivateKey",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastScanned",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "error",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "branchName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "policyDirectories",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "policyFileSuffix",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddRepositoryMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RepositoryPayload",
        "kind": "LinkedField",
        "name": "addRepository",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddRepositoryMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RepositoryPayload",
        "kind": "LinkedField",
        "name": "addRepository",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "repository",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "RepositoryEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "404a7ed04db116542d1131cb0380b870",
    "id": null,
    "metadata": {},
    "name": "AddRepositoryMutation",
    "operationKind": "mutation",
    "text": "mutation AddRepositoryMutation(\n  $input: RepositoryInput!\n) {\n  addRepository(input: $input) {\n    repository {\n      id\n      name\n      url\n      head\n      authUser\n      hasAuthToken\n      hasSshPassphrase\n      hasSshPrivateKey\n      lastScanned\n      description\n      error\n      branchName\n      policyDirectories\n      policyFileSuffix\n      uuid\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "085589569d49b3ca16df7c1611a72d30";

export default node;
