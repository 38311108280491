import {
  createContext,
  useContext,
  useState,
  useTransition,
  type PropsWithChildren,
} from "react";

import { useParams, useSearchParams } from "react-router";

import {
  COST_DASHBOARD_ACTION_BAR_TABS,
  getInitialActionBarState,
  updateActionBarState,
  type CostDashboardActionBarState,
} from "../utils/costDashboardActionBar";
import {
  type CostDashboardFilterBarState,
  getInitialFilterBarState,
  updateFilterBarState,
} from "../utils/costDashboardFilterBar";

type CostDashboardBarStateContext = {
  filterBarState: CostDashboardFilterBarState;
  applyFilterBarState: (newState: CostDashboardFilterBarState) => void;
  isFilterBarPending: boolean;
  actionBarState: CostDashboardActionBarState;
  applyActionBarState: (newState: CostDashboardActionBarState) => void;
  isActionBarPending: boolean;
} | null;

export const CostDashboardBarStateContext =
  createContext<CostDashboardBarStateContext>(null);

export const useCostDashboardBarState = () => {
  const context = useContext(CostDashboardBarStateContext);
  if (!context) {
    throw new Error(
      "useCostDashboardBarState must be used within a CostDashboardBarStateProvider",
    );
  }
  return context;
};

export function CostDashboardBarStateProvider({ children }: PropsWithChildren) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { sectionKey } = useParams();
  const hasSelectedOpportunity = Boolean(sectionKey);

  const [filterBarState, setFilterBarState] = useState(() =>
    getInitialFilterBarState(searchParams),
  );

  const [actionBarState, setActionBarState] = useState(() =>
    getInitialActionBarState(searchParams, hasSelectedOpportunity),
  );

  const [isFilterBarPending, startFilterBarTransition] = useTransition();
  const [isActionBarPending, startActionBarTransition] = useTransition();

  function applyFilterBarState(newState: CostDashboardFilterBarState) {
    setFilterBarState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        ...newState.filters,
      },
      locked:
        newState.locked !== undefined ? newState.locked : prevState.locked,
    }));

    startFilterBarTransition(() =>
      updateFilterBarState(
        { ...filterBarState, ...newState },
        searchParams,
        setSearchParams,
      ),
    );
  }

  function applyActionBarState(newState: CostDashboardActionBarState) {
    setActionBarState((prevState) => ({
      ...prevState,
      ...newState,
    }));

    startActionBarTransition(() =>
      updateActionBarState(
        { ...actionBarState, ...newState },
        searchParams,
        setSearchParams,
      ),
    );
  }

  // set a default tab on the opportunity details view when navigating from the list view
  // as "opportunities" is not a valid tab in that context
  if (
    hasSelectedOpportunity &&
    actionBarState.tab == COST_DASHBOARD_ACTION_BAR_TABS.default.opportunities
  ) {
    setActionBarState((prevState) => ({
      ...prevState,
      tab: COST_DASHBOARD_ACTION_BAR_TABS.opportunitySelected.accounts,
    }));
  }

  return (
    <CostDashboardBarStateContext.Provider
      value={{
        filterBarState,
        applyFilterBarState,
        isFilterBarPending,
        actionBarState,
        applyActionBarState,
        isActionBarPending,
      }}
    >
      {children}
    </CostDashboardBarStateContext.Provider>
  );
}
