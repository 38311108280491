import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { graphql, useFragment } from "react-relay";

import { useConfig } from "app/contexts";

import AppVersion from "./AppVersion";
import Copyright from "./Copyright";
import ExternalLinks from "./ExternalLinks";
import { Support } from "./Support";

import type { Footer_platform$key } from "./__generated__/Footer_platform.graphql";

const Root = styled("footer")(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  padding: theme.spacing(2, 2),
  marginTop: "auto",
  backgroundColor: theme.palette.grey[200],
}));

interface Props {
  queryRef: Footer_platform$key;
}

export function Footer({ queryRef }: Props) {
  const data = useFragment(
    graphql`
      fragment Footer_platform on Query {
        ...Support_whoami
        platform {
          ...AppVersion_version
        }
      }
    `,
    queryRef,
  );

  const config = useConfig();
  const supportEnabled = !config?.is_poc;

  return (
    <Root>
      <Container maxWidth="lg">
        <Grid
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          container
        >
          <Grid item>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: 100,
              }}
            >
              {supportEnabled ? <Support queryRef={data} /> : null}
              <ExternalLinks />
            </Box>
          </Grid>
          <Grid item>
            <AppVersion platform={data.platform} />
            <Copyright />
          </Grid>
        </Grid>
      </Container>
    </Root>
  );
}
