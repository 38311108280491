/**
 * @generated SignedSource<<b76ae772b05fa4d1f48bd224da485521>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SideBarWrapper_query$data = {
  readonly UrlConfig: {
    readonly jun0: string | null | undefined;
    readonly redash: string | null | undefined;
    readonly sinistral: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"useEnabledDashboards_query">;
  readonly " $fragmentType": "SideBarWrapper_query";
};
export type SideBarWrapper_query$key = {
  readonly " $data"?: SideBarWrapper_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"SideBarWrapper_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterElement"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SideBarWrapper_query",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "filterElement",
          "variableName": "filterElement"
        }
      ],
      "kind": "FragmentSpread",
      "name": "useEnabledDashboards_query"
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UrlConfig",
          "kind": "LinkedField",
          "name": "UrlConfig",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "jun0",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "redash",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sinistral",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "21643e6625c7dc58f9c40ab8d6ff7d11";

export default node;
