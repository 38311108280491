import { graphql, useFragment } from "react-relay";

import type { useEnabledDashboards_query$key } from "./__generated__/useEnabledDashboards_query.graphql";

export function useEnabledDashboards(queryRef: useEnabledDashboards_query$key) {
  const data = useFragment(
    graphql`
      fragment useEnabledDashboards_query on Query
      @argumentDefinitions(filterElement: { type: "FilterElementInput" }) {
        dashboards(filterElement: $filterElement) {
          edges {
            node {
              key
              title
            }
          }
        }
      }
    `,
    queryRef,
  );

  const enabledDashboards = (data.dashboards.edges ?? []).map((edge) => {
    const { title, key } = edge.node;
    return {
      label: title,
      id: title.toLocaleLowerCase(),
      key: key,
      target: `/dashboards/${key}`,
    };
  });

  return enabledDashboards;
}
