import { COST_DASHBOARD_ACTION_BAR_TABS } from "./costDashboardActionBar";

import type { BinaryOperator, Filter } from "@cubejs-client/core";

export type OpportunityCube = "CostOpportunity" | "CostOpportunityTag";

export interface CostDashboardFilters {
  accountName: string[];
  accountId: string[];
  accountGroup: string[];
  resourceTag: string[];
  search: string[];
}

export interface CostDashboardFilterBarState {
  filters: CostDashboardFilters;
  locked: boolean;
}

export const MOCK_COST_DASHBOARD_FILTER_BAR_OPTIONS: CostDashboardFilters = {
  accountName: ["Account 1", "Account 2", "Account 3"],
  accountId: ["ID 1", "ID 2", "ID 3"],
  accountGroup: ["Group 1", "Group 2", "Group 3"],
  resourceTag: ["Tag 1", "Tag 2", "Tag 3"],
  search: ["Search 1"],
};

// Object to map the filters to the url param keys
export const COST_DASHBOARD_FILTER_BAR_KEY_MAP = {
  filters: {
    accountName: "account-name",
    accountId: "account-id",
    accountGroup: "account-group",
    resourceTag: "resource-tag",
    search: "search",
  },
  locked: "locked",
} as const;

export const DEFAULT_FILTER_BAR_STATE: CostDashboardFilterBarState = {
  filters: {
    accountName: [],
    accountId: [],
    accountGroup: [],
    resourceTag: [],
    search: [],
  },
  locked: false,
} as const;

// Placeholder text for search bar based on the current tab
export const SEARCH_PLACEHOLDER_BY_TAB = {
  [COST_DASHBOARD_ACTION_BAR_TABS.default.accounts]:
    "Search accounts by name, or id",
  [COST_DASHBOARD_ACTION_BAR_TABS.default.opportunities]:
    "Search opportunities by title",
  [COST_DASHBOARD_ACTION_BAR_TABS.default.resources]:
    "Search resources by resource id",
  [COST_DASHBOARD_ACTION_BAR_TABS.default.tag]: "Search tags by value",
} as const;

// Object to map the tabs to the cube search filters
export const SEARCH_FILTERS_BY_TAB = {
  [COST_DASHBOARD_ACTION_BAR_TABS.default.accounts]: (
    searchTerm: string,
    cube: string,
  ) => [
    {
      member: `${cube}.account_name`,
      operator: "contains" as BinaryOperator,
      values: [searchTerm],
    },
    {
      member: `${cube}.account_id`,
      operator: "contains" as BinaryOperator,
      values: [searchTerm],
    },
  ],
  [COST_DASHBOARD_ACTION_BAR_TABS.default.resources]: (
    searchTerm: string,
    cube: string,
  ) => [
    {
      member: `${cube}.resource_id`,
      operator: "contains" as BinaryOperator,
      values: [searchTerm],
    },
  ],
  [COST_DASHBOARD_ACTION_BAR_TABS.default.tag]: (
    searchTerm: string,
    cube: string,
  ) => [
    {
      member: `${cube}.resource_tag_value`,
      operator: "contains" as BinaryOperator,
      values: [searchTerm],
    },
  ],
};

/**
 * Gets intital state of filter bar from url params
 *
 * @param searchParams - search params from useSearchParams
 * @returns initial state of the filter bar
 */
export function getInitialFilterBarState(searchParams: URLSearchParams) {
  const filters = structuredClone(DEFAULT_FILTER_BAR_STATE);

  Object.keys(COST_DASHBOARD_FILTER_BAR_KEY_MAP.filters).forEach((filter) => {
    const filterKey =
      COST_DASHBOARD_FILTER_BAR_KEY_MAP.filters[
        filter as keyof typeof COST_DASHBOARD_FILTER_BAR_KEY_MAP.filters
      ];

    const param = searchParams.get(filterKey);
    let paramFilterList: string[] = [];

    if (param) paramFilterList = param.split(",");
    (filters.filters[filter as keyof CostDashboardFilters] as string[]) =
      paramFilterList;
  });

  filters.locked =
    searchParams.get(COST_DASHBOARD_FILTER_BAR_KEY_MAP.locked) === "true";

  return filters;
}

/**
 * Updates search params on changes in filter bar state
 *
 * @param filterBarState - current state of filter bar
 * @param searchParams - search params from useSearchParams
 * @param setSearchParams - setter function from useSearchParams
 */
export function updateFilterBarState(
  filterBarState: CostDashboardFilterBarState,
  searchParams: URLSearchParams,
  setSearchParams: (
    nextInit: URLSearchParams,
    navigateOptions?:
      | {
          replace?: boolean | undefined;
          state?: unknown;
        }
      | undefined,
  ) => void,
) {
  Object.keys(COST_DASHBOARD_FILTER_BAR_KEY_MAP.filters).forEach((filter) => {
    const filterKey =
      COST_DASHBOARD_FILTER_BAR_KEY_MAP.filters[
        filter as keyof typeof COST_DASHBOARD_FILTER_BAR_KEY_MAP.filters
      ];

    searchParams.set(
      filterKey,
      filterBarState.filters[filter as keyof CostDashboardFilters].join(","),
    );
  });

  searchParams.set(
    COST_DASHBOARD_FILTER_BAR_KEY_MAP.locked,
    String(filterBarState.locked),
  );

  setSearchParams(searchParams);
}

export type FilterBarCubeFilters = [
  {
    or: Filter[];
  },
  {
    and: Filter[];
  },
];

/**
 * Builds Cube.js query filters from filter bar state
 *
 * @param filters - current state of filter bar
 * @returns Cube.js query filters
 */
export function buildQueryFilters(
  filters: CostDashboardFilters,
  cube = "CostOpportunity",
  tab: string,
): FilterBarCubeFilters {
  const { search, ...filterBarFilters } = filters;

  const queryFilters: Filter[] = [];
  let tagFilters: Filter[] = [];

  const searchTerm = search[0] || "";
  const searchFilters =
    tab !== "Opportunities" ? buildSearchFilters(searchTerm, tab, cube) : [];

  const createFilter = (
    member: string,
    values: string[],
    operator: BinaryOperator = "equals",
  ): Filter => ({
    member,
    operator,
    values,
  });

  interface filterConfig {
    key: keyof typeof filterBarFilters;
    field: string;
    altField: string;
  }
  const filterConfigs: filterConfig[] = [
    { key: "accountName", field: "account_name", altField: "name" },
    { key: "accountGroup", field: "account_groups", altField: "groups" },
    { key: "accountId", field: "account_id", altField: "key" },
  ];

  filterConfigs.forEach(({ key, field, altField }) => {
    if (filterBarFilters[key].length > 0) {
      const filterField =
        cube === "Account" ? `${cube}.${altField}` : `${cube}.${field}`;
      const value =
        key === "accountGroup"
          ? filters[key].map((group) => group.split("=")[0])
          : filters[key];
      queryFilters.push(
        createFilter(
          filterField,
          value,
          key !== "accountId" ? "contains" : undefined,
        ),
      );
    }
  });

  if (filterBarFilters.resourceTag.length > 0) {
    tagFilters = filterBarFilters.resourceTag.map((tag) => {
      const [key, value] = tag.split("=");
      // filtering just by key
      if (key === "key") {
        return createFilter(`${cube}.resource_tag_key`, [value]);
        // filtering just by value
      } else if (key === "value") {
        return createFilter(`${cube}.resource_tag_value`, [value]);
      }
      // filtering by both key and value exact match
      return {
        and: [
          createFilter(`${cube}.resource_tag_key`, [key]),
          createFilter(`${cube}.resource_tag_value`, [value]),
        ],
      };
    });
  }
  return [
    {
      or: searchFilters,
    },
    {
      and: [...queryFilters, ...tagFilters],
    },
  ];
}

/**
 * Builds Cube.js query filters for search term
 * @param search - search term from filter bar search
 * @param tab - current action bar tab
 * @param cube - cube depending on filters
 * @returns cube filters for search term
 */
export function buildSearchFilters(
  search: string,
  tab: string,
  cube = "CostOpportunity",
) {
  return search
    ? SEARCH_FILTERS_BY_TAB[tab as keyof typeof SEARCH_FILTERS_BY_TAB](
        search,
        cube,
      )
    : [];
}

/**
 * Checks if the filters include account filters (needed to switch the dimensions for the cube query)
 *
 * @param filters - current state of filter bar
 * @returns boolean indicating if the filters include account filters
 */
export function hasAccountFilters(filters?: FilterBarCubeFilters) {
  const filtersFlat = filters ? [...filters[0].or, ...filters[1].and] : [];
  return filtersFlat.some((filter) => {
    if ("member" in filter) {
      return (
        filter.member?.includes("account") || filter.member?.includes("group")
      );
    }
  });
}
