import type React from "react";

import Alert from "@mui/material/Alert";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Snackbar from "@mui/material/Snackbar";

import type { AlertColor } from "@mui/material/Alert";

export interface AlertStatus {
  message: string | string[];
  severity: AlertColor;
  duration?: number;
}

interface Props {
  alert: AlertStatus;
  openAlert: boolean;
  setOpenAlert: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ApplicationAlert({
  alert,
  openAlert,
  setOpenAlert,
}: Props): JSX.Element {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      autoHideDuration={alert.duration ?? 2000}
      onClose={() => setOpenAlert(false)}
      open={openAlert}
    >
      <Alert severity={alert.severity}>
        {Array.isArray(alert.message) ? (
          <List>
            {alert.message.map((msg, index) => (
              <ListItem key={index}>{msg}</ListItem>
            ))}
          </List>
        ) : (
          alert.message
        )}
      </Alert>
    </Snackbar>
  );
}
