import { graphql } from "react-relay";

export const SetAwsAccessRolesMutation = graphql`
  mutation SetAwsAccessRolesMutation($input: AWSAccessRoleInput!) {
    setAWSAccessRoles(input: $input) {
      primary {
        arn
        error
      }
      accountDiscovery {
        arn
        error
      }
      readOnly {
        arn
        error
      }
      remediationPolicy {
        arn
        error
      }
    }
  }
`;
