/**
 * @generated SignedSource<<46c29e1c24bdb523ab2d556c02e7dfef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccessModelTabRefetchQuery$variables = Record<PropertyKey, never>;
export type AccessModelTabRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccessModelTab_awsAccessRoles">;
};
export type AccessModelTabRefetchQuery = {
  response: AccessModelTabRefetchQuery$data;
  variables: AccessModelTabRefetchQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccessModelTabRefetchQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AccessModelTab_awsAccessRoles"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AccessModelTabRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AWSAccessRoles",
        "kind": "LinkedField",
        "name": "awsAccessRoles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountDiscoveryRoleARN",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "primaryRoleARN",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "readOnlyRoleARN",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "remediationPolicyRoleARN",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "samplePrimaryTrust",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "externalID",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e1bdec31f1a37af4125e7e5077ea7a4c",
    "id": null,
    "metadata": {},
    "name": "AccessModelTabRefetchQuery",
    "operationKind": "query",
    "text": "query AccessModelTabRefetchQuery {\n  ...AccessModelTab_awsAccessRoles\n}\n\nfragment AccessModelTab_awsAccessRoles on Query {\n  ...GatewayRolesForm_awsAccessRoles\n  awsAccessRoles {\n    externalID\n    primaryRoleARN\n    samplePrimaryTrust\n  }\n}\n\nfragment GatewayRolesForm_awsAccessRoles on Query {\n  awsAccessRoles {\n    accountDiscoveryRoleARN\n    primaryRoleARN\n    readOnlyRoleARN\n    remediationPolicyRoleARN\n    samplePrimaryTrust\n  }\n}\n"
  }
};

(node as any).hash = "71432517c18fbfb3722d19cdf8fd7c5a";

export default node;
