import {
  Authenticator,
  Button,
  View,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import Box from "@mui/material/Box";
import GlobalStyles from "@mui/material/GlobalStyles";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Stack from "@mui/system/Stack";
import { COLORS } from "@stacklet/ui";
import { Auth } from "aws-amplify";

import { storeRedirectReturnCookie } from "app/utils/redirection";
import StackletLogo from "assets/images/logo_vertical_color_1.svg?react";
import RelayEnvironment from "RelayEnvironment";

import type { CognitoConfigRecord } from "bootstrap";

export function fedSignIn(id: string, cognitoConfig: CognitoConfigRecord) {
  window.location.assign(
    `https://${cognitoConfig?.cognitoInstall}/oauth2/authorize?response_type=token&client_id=${cognitoConfig?.userPoolWebClientId}&idp_identifier=${id}&redirect_uri=${window.origin}&scope=email+openid+aws.cognito.signin.user.admin`,
  );
}

const authenticatorComponents = {
  Header() {
    return (
      <View>
        <Stack
          alignItems="center"
          direction="column"
          justifyContent="center"
          spacing={1}
          sx={{ mb: 4 }}
        >
          <StackletLogo height="64" width="64" />
          <Typography align="center" variant="h4">
            Sign In
          </Typography>
        </Stack>
      </View>
    );
  },
  SignIn: {
    Footer() {
      const { toResetPassword } = useAuthenticator();

      const cognitoConfig: CognitoConfigRecord = RelayEnvironment.getStore()
        .getSource()
        .get("Cognito");

      if (typeof cognitoConfig?.saml === "string") {
        try {
          const samlTargets = JSON.parse(cognitoConfig.saml);
          const buttons = Object.keys(samlTargets).map((d, key) => {
            return (
              <Button
                data-testid={key}
                key={key}
                onClick={() => fedSignIn(d, cognitoConfig)}
                slot="federated-buttons"
                style={{ backgroundColor: COLORS.orange.base, color: "white" }}
              >
                {samlTargets[d]}
              </Button>
            );
          });
          return (
            <View textAlign="center">
              <div>{buttons}</div>
              <Button
                fontWeight="normal"
                onClick={toResetPassword}
                size="small"
                variation="link"
              >
                Reset Password
              </Button>
            </View>
          );
        } catch (error) {
          console.error(error);
        }
      } else {
        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toResetPassword}
              size="small"
              variation="link"
            >
              Reset Password
            </Button>
          </View>
        );
      }
      return <></>; // amplify types don't allow null here.
    },
  },
};

const Root = styled("div")(({ theme }) => ({
  "--amplify-components-button-primary-background-color":
    theme.palette.primary.main,
  "--amplify-components-button-primary-hover-background-color":
    COLORS.orange.L30,
  "[data-amplify-authenticator] [data-amplify-container]": {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  display: "grid",
  gridTemplateColumns: "1.5fr 2fr",
}));

interface Props {
  children: React.ReactNode;
}

export default function Login({ children }: Props) {
  const { route } = useAuthenticator((context) => [context.route]);
  const searchParams = new URLSearchParams(window.location.search);

  if (searchParams.has("logout")) {
    Auth.signOut().then(() => window.location.assign(window.location.origin));
    return null;
  }

  if (route === "authenticated") {
    return <>{children}</>;
  }

  if (route === "signIn") {
    // We're about to render the signIn form - store the current URL so we can
    // redirect to it after the signIn event.
    storeRedirectReturnCookie();
  }

  return (
    <Root>
      <GlobalStyles styles={{ body: { margin: 0 } }} />
      <Authenticator components={authenticatorComponents} hideSignUp={true}>
        {children}
      </Authenticator>
      <Box
        sx={{
          backgroundColor: "secondary.main",
        }}
      />
    </Root>
  );
}
