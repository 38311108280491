import { useMemo } from "react";

import { useAuthenticator } from "@aws-amplify/ui-react";

import type { AmplifyUser } from "@aws-amplify/ui";

interface Identity {
  userId: string;
  [key: string]: string;
}

export type UserWithIdentity = AmplifyUser & { identity?: Identity };

function getIdentity(user: AmplifyUser) {
  let identity;
  if (user?.attributes?.identities) {
    try {
      identity = JSON.parse(user.attributes.identities)[0] as Identity;
    } catch {
      // return empty
    }
  }
  return identity;
}

export function useUserWithIdentity() {
  const { user } = useAuthenticator((context) => [context.user]);

  const identity = useMemo(() => getIdentity(user), [user]);

  return { ...user, identity } as UserWithIdentity;
}
