/**
 * @generated SignedSource<<a8cc593b1ec0569042f32e8b786350c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GatewayRolesForm_awsAccessRoles$data = {
  readonly awsAccessRoles: {
    readonly accountDiscoveryRoleARN: string | null | undefined;
    readonly primaryRoleARN: string | null | undefined;
    readonly readOnlyRoleARN: string | null | undefined;
    readonly remediationPolicyRoleARN: string | null | undefined;
    readonly samplePrimaryTrust: string;
  };
  readonly " $fragmentType": "GatewayRolesForm_awsAccessRoles";
};
export type GatewayRolesForm_awsAccessRoles$key = {
  readonly " $data"?: GatewayRolesForm_awsAccessRoles$data;
  readonly " $fragmentSpreads": FragmentRefs<"GatewayRolesForm_awsAccessRoles">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GatewayRolesForm_awsAccessRoles",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AWSAccessRoles",
      "kind": "LinkedField",
      "name": "awsAccessRoles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountDiscoveryRoleARN",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "primaryRoleARN",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "readOnlyRoleARN",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "remediationPolicyRoleARN",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "samplePrimaryTrust",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "487573f7b0b7b7de1489874a397cf5df";

export default node;
