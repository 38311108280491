import type { PropsWithChildren } from "react";

import { graphql, useFragment } from "react-relay";

import { PageWithBreadCrumbs } from "app/components/layout/PageWithBreadCrumbs";

import type { AdminSettingsPage_query$key } from "./__generated__/AdminSettingsPage_query.graphql";

interface Props extends PropsWithChildren {
  queryRef: AdminSettingsPage_query$key;
}

export function AdminSettingsPage({ queryRef, children }: Props) {
  const data = useFragment(
    graphql`
      fragment AdminSettingsPage_query on Query {
        ...PageWithBreadCrumbs_query
      }
    `,
    queryRef,
  );

  const breadcrumbs = [
    { label: "Settings" },
    {
      tabs: [{ label: "Admin", target: `/settings/admin` }],
    },
  ];

  return (
    <PageWithBreadCrumbs
      breadcrumbs={breadcrumbs}
      queryRef={data}
      title="Admin"
    >
      {children}
    </PageWithBreadCrumbs>
  );
}
